import '../controllers'

//= require apexcharts
//= require("trix")
//= require("@rails/actiontext")
//= require("highlight")
import('trix')
// import * as Turbo from '@hotwired/turbo'
// import '@hotwired/turbo-rails'
// Turbo.start()
// Turbo.session.drive = true

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
